import React, { useEffect, useState } from "react";
import Opensign from "@opensign/react";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { isEurope, isStaging } from "../constant/Const";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function RecipientSign() {
  const query = useQuery();
  const [loadScript, setLoadScript] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoadScript(true);
    }, 3000);
  }, []);

  return (
    <div>
      {!loadScript && <Loader />}
      <Opensign
        onLoad={() => {
          setLoadScript(true);
        }}
        templateId={query?.get("templateid")}
        baseUrl={
          isStaging
            ? "https://staging-app.opensignlabs.com/api/app"
            : isEurope
            ? "https://eu-app.opensignlabs.com/api/app"
            : "https://app.opensignlabs.com/api/app"
        }
        // appId={isStaging ? "opensign" : "legadranaxn"}
        appId="opensign"
         
      />
    </div>
  );
}

export default RecipientSign;
