import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import TemplateList from "./pages/TemplateList";
import { useEffect, useState } from "react";
import Parse from "parse";
import RecipientSign from "./pages/RecipientSign";
import PageNotFound from "./pages/PageNotFound";
import Layout from "./pages/Layout";
import Loader from "./components/Loader";
import { isEurope, isStaging } from "./constant/Const";
function App() {
  const [isloading, setIsLoading] = useState(true);

  const serverUrl = isStaging
    ? "https://staging-app.opensignlabs.com/api/app/"
    : isEurope
    ? "https://eu-app.opensignlabs.com/api/app/"
    : "https://app.opensignlabs.com/api/app/";
  // const appId = isStaging ? "opensign" : "legadranaxn";
  const appId = "opensign";

  Parse.initialize(appId);
  Parse.serverURL = serverUrl;
  useEffect(() => {
    saveLocalStorage();
  });

  const saveLocalStorage = async () => {
    try {
      localStorage.clear();
      localStorage.setItem("parseAppId", appId);
      localStorage.setItem("baseUrl", serverUrl);
      localStorage.setItem(
        "appLogo",
        "/static/media/logo.2a7bff0c1189183fafe7.png"
      );
      localStorage.setItem("_appName", "contracts");
      setIsLoading(false);
    } catch (error) {
      console.log("err ", error);
    }
  };
  return (
    <div>
      {isloading ? (
        <div className="flex justify-center items-center h-[100vh]">
          <Loader />
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/:username" element={<TemplateList />} />
            </Route>
            <Route path="/publicsign" element={<RecipientSign />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
